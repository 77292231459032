/**************** banner part css start ****************/
.banner_part{
    height: 800px;
    position: relative;
    overflow: hidden;
    background-image: url(../img/banner_img.png);
    background-repeat: no-repeat;
    background-size: content;
    background-position: center;
    z-index: 1;
    &:after{
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $btn_hover;
        z-index: -1;
        opacity: .7;
    }
    @media #{$tab}{
        height: 650px;
    }
    @media #{$medium_device}{
        height: 650px;
    }
    
    .banner_text{
        display: table;
        width: 100%;
        height: 800px;
        .banner_text_iner{
            display: table-cell;
            vertical-align: middle;
            @media #{$tab}{
                vertical-align: middle;
            }

        }
        @media #{$tab}{
            text-align: center;
            padding-top: 0px;
            height: 650px;
        }
        @media #{$medium_device}{
            height: 650px;
        }
        h5{
            font-size: 20px;
            text-transform: capitalize;
            font-weight: 500;
            color: $white_color;
            margin-bottom: 38px;
            @media #{$tab}{
                margin-bottom: 15px;
            }
        }
        h1{
            font-size: 65px;
            text-transform: capitalize;
            font-weight: 700;
            margin-bottom: 27px;
            line-height: 75px;
            color: $white_color;
            @media #{$tab}{
                font-size: 30px;
                margin-bottom: 5px;
                line-height: 1.3;
            }
            @media #{$medium_device}{
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.4;
            }
            span{
                color: $btn_bg;
            }
        }
        
        p{
            font-size: 15px;
            line-height: 1.8;
            font-family: $font_stack_1; 
            color: $font_7;
        }
        .btn_1{
            margin-top: 26px;
        }
    }
}
  
/**************** hero part css end ****************/
